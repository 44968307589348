<template>
  <div class="c-cookie">
    <div id="js-cookieClose" class="o-cookie__close" @click.prevent="cookieClose"></div>
    <div class="o-cookie__content">
      <div class="o-cookie__leftWrap">
        <h4>Cookies</h4>
        <p>
          We use cookies to improve your browsing experience.
          Continuing to use this site means you agree to our use of cookies.
        </p>
      </div>
      <div class="o-cookie__rightWrap">
        <router-link class="o-cookie__link" to="/policy">Privacy Policy</router-link>
        <button @click.prevent="allowBtn" class="o-button" id="allowBtn">Allow</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.checkCookie();
  },
  methods: {
    checkCookie() {
      let cookie_enabled = this.getCookie("ecstorage_cookiesenbaled");
      if (cookie_enabled != "") {
        document.querySelector(".c-cookie").style.display = "none";
      } else {
        document.querySelector(".c-cookie").style.display = "block";
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    allowBtn() {
      let d = new Date();
      let cname = "ecstorage_cookiesenbaled";
      let cvalue = "true";
      let exdays = "1";
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      document.querySelector(".c-cookie").style.display = "none";
    },
    cookieClose() {
      document.querySelector(".c-cookie").style.display = "none";
    }
  }
};
</script>

<style>
</style>