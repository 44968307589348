import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        storages: null,
        branches: null,
        branchSelect: 0,
        spaces: null,
        startDate: '',
        endDate: '',
        spaceSelect: '',
        sliderSelect: null
    },
    getters: {
        branchSpacesGet: (state) => {
            if (state.branches && state.branchSelect) {
                // x.id is number, state.branchSelect is a string
                const branchSpaces = state.branches.find(x => x.id === parseInt(state.branchSelect))
                // kd, changed branches' storage relation name
                return branchSpaces.storages
            }
        }
    },
    mutations: {
        storagesGet: (state, payload) => {
            state.storages = payload
        },
        branchesGet: (state, payload) => {
            state.branches = payload
        },
        startDateSelect: (state, payload) => {
            state.startDate = payload
        },
        endDateSelect: (state, payload) => {
            state.endDate = payload
        },
        spacesGet: (state, payload) => {
            state.spaces = payload
        },
        branchSelect: (state, payload) => {
            state.branchSelect = payload
        },
        spaceSelect: (state, payload) => {
            state.spaceSelect = payload
        },
        sliderSelect: (state, payload) => {
            state.sliderSelect = payload
        }
    },
    actions: {
        storagesGet: ({ commit }, payload) => {
            commit('storagesGet', payload)
        },
        branchesGet: ({ commit }, payload) => {
            commit('branchesGet', payload)
        },
        spacesGet: ({ commit }, payload) => {
            commit('spacesGet', payload)
        },
        startDateSelect: ({ commit }, payload) => {
            commit('startDateSelect', payload)
        },
        endDateSelect: ({ commit }, payload) => {
            commit('endDateSelect', payload)
        },
        branchSelect: ({ commit }, payload) => {
            commit('branchSelect', payload)
        },
        spaceSelect: ({ commit }, payload) => {
            commit('spaceSelect', payload)
        },
        sliderSelect: ({ commit }, payload) => {
            commit('sliderSelect', payload)
        }
    }
})