<template>
  <div>
    <Header />
    <router-view></router-view>
    <Footer />
    <Cookie />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cookie from "./components/Cookie";

/**VIEWS */
import Home from "./components/views/Home";

import { mapActions } from "vuex";

export default {
  components: {
    Header,
    Footer,
    Cookie
  },
  data() {
    return {};
  },
  created() {
    this.getStorageType();
    this.getBranchList();
  },
  methods: {
    ...mapActions(["storagesGet", "branchesGet"]),
    getStorageTypeAction(payload) {
      this.$store.dispatch("storagesGet", payload);
    },
    getBranchesAction(payload) {
      this.$store.dispatch("branchesGet", payload);
    },
    setBranchSelectDefault(payload) {
      this.$store.dispatch("branchSelect", payload);
    },
    getStorageType() {
      axios
        .get("/api/storage-types")
        .then(response => {
          const payload = response.data;
          this.getStorageTypeAction(payload);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getBranchList() {
      axios
        .get("/api/branches")
        .then(response => {
          const payload = response.data;
          this.getBranchesAction(payload);

          // get first data of branches
          // const branchId = payload[1].id;
          // if(branchId) {
          //     this.setBranchSelectDefault(branchId)
          // }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style>
</style>