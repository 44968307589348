<template>
  <div id="js-services" class="c-services">
    <h1 class="o-heading__h1">THE EC ADVANTAGE</h1>
    <div class="o-service__services">
      <div class="o-service__service">
        <div class="o-imgWrap">
          <img src="/images/fservice__affordable.png" alt />
        </div>
        <div class="o-service__info">
          <div class="o-service__h1">AFFORDABLE</div>
          <p>
            With all our services (packing, trucking, and storing)
            we are confident that we are more affordable than most
            self-storage facilities.
          </p>
        </div>
      </div>
      <div class="o-service__service">
        <div class="o-imgWrap">
          <img src="/images/fservice__experience.png" alt />
        </div>
        <div class="o-service__info">
          <div class="o-service__h1">WORRY-FREE EXPERIENCE</div>
          <p>
            We work closely with you every step of the way from
            packing, trucking and storing.
            We guarantee an EC storing experience for you.
          </p>
        </div>
      </div>
      <div class="o-service__service">
        <div class="o-imgWrap">
          <img src="/images/fservice__trucking.png" alt />
        </div>
        <div class="o-service__info">
          <div class="o-service__h1">CUSTOM SIZES & PACKAGES</div>
          <p>
            No package or item is too big. Customizable units
            and flexible payment options are available by giving us a call.
          </p>
        </div>
      </div>
      <div class="o-service__service">
        <div class="o-imgWrap">
          <img src="/images/fservice__secured.png" alt />
        </div>
        <div class="o-service__info">
          <div class="o-service__h1">SECURED FACILITY</div>
          <p>
            Every branch is equipped with CCTV and guarded 24/7,
            allowing our clients to have the peace of mind knowing their
            items are safe and secured.
          </p>
        </div>
      </div>
      <div class="o-service__service">
        <div class="o-imgWrap">
          <img src="/images/fservice__location.png" alt />
        </div>
        <div class="o-service__info">
          <div class="o-service__h1">CONVENIENT & SAFE LOCATIONS</div>
          <p>
            All facilities are located in safe and flood-free neighborhoods
            near major business and residential hubs making it easier to
            move items in and out of our units.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>