<template>
  <div id="js-spaces" class="c-spaces">
    <h1 class="o-heading__h1">Storage Spaces</h1>
    <div class="o-spaces__mainWrap">
      <slick ref="slick" :options="slickOptions" class="c-slider">
        <div class="o-spaces__wrap" v-for="data in spacesStorages" :key="data.id">
          <div class="o-imgWrap">
            <img :src="`${data.unit_image}`" alt />
          </div>
          <div class="o-heading__h3" v-text="data.unit_type"></div>
          <div class="o-spaces__toggle">
            <div v-html="data.details"></div>
          </div>
          <div class="o-buttonWrap">
            <template v-if="!checkCustom.test(data.unit_type)">
              <a
                :data-id="data.id"
                @click.prevent="setSliderSelect(data.id)"
                class="o-button js-popupOpen"
                data-popup="popup-space"
                href="javascript:void(0)"
              >More</a>
            </template>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
// https://github.com/staskjs/vue-slick
// https://stackoverflow.com/questions/46487333/vuejs-slick-is-not-working-with-v-for
import Slick from "vue-slick";

// popupES6 Plugin
import Popup from "./plugins/PopupPlugin";

export default {
  components: {
    Slick
  },
  props: ["spacesStorages"],
  data() {
    return {
      checkCustom: /custom/i,
      slickOptions: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 3,
        arrows: false,
        responsive: [
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 10000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  created() {
    // this.getStorageType();
  },
  mounted() {
    this.popupInit();
    this.resetSliderSelect();
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy();
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions);
        // popup
        this.popupInit();
      }
    });
  },

  methods: {
    popupInit() {
      const popup = new Popup();
      popup.init();
    },
    resetSliderSelect() {
      this.$store.dispatch("sliderSelect", null);
    },
    setSliderSelect(id) {
      this.$store.dispatch("sliderSelect", id);
    }
  }
};
</script>

<style>
</style>