<template>
  <div>
    <header>
      <div class="c-header" id="js-header">
        <a to="/" class="o-header__logo"></a>
        <div class="o-header__navigationWrap">
          <div class="o-header__subContact" id="js-header__subContact">
            <a class="o-header__subContact--phone" href="tel:+639059402911">0905 940 2911</a>
            <a
              class="o-header__subContact--email"
              href="mailto:ecstorageph@gmail.com"
            >ecstorageph@gmail.com</a>
          </div>
          <div class="o-header__menuWrap">
            <router-link class="o-button" to="/book">Book Now!</router-link>
            <a
              id="js-header__menu"
              class="o-header__menu js-header__menu"
              href="javascript:void(0)"
            >Menu</a>
          </div>
        </div>
      </div>
    </header>

    <div
      :class="['c-headerFix', scrollDirection == 'up' ? 'c-headerFix--hide' : 'c-headerFix--show']"
    >
      <div class="c-headerFixWrap" id="js-headerFix">
        <router-link to="/" class="o-headerFix__logo"></router-link>
        <div class="o-headerFix__navigationWrap">
          <div class="o-headerFix__subContact" id="js-headerFix__subContact">
            <a class="o-headerFix__subContact--phone" href="tel:+639059402911">0905 940 2911</a>
            <a
              class="o-headerFix__subContact--email"
              href="mailto:ecstorageph@gmail.com"
            >ecstorageph@gmail.com</a>
          </div>
          <div class="o-headerFix__menuWrap">
            <router-link class="o-button" to="/book">Book Now!</router-link>
            <a
              id="js-header__menu"
              class="o-headerFix__menu js-header__menu"
              href="javascript:void(0)"
            >Menu</a>
          </div>
        </div>
      </div>
    </div>

    <div id="js-menu__mask" class="o-menu__mask"></div>

    <nav id="js-menu" class="c-menu">
      <div id="js-menu__close" class="o-menu__close"></div>
      <ul class="o-menu__main">
        <li>
          <router-link to="/" exact>Home</router-link>
        </li>
        <li>
          <a
            @click.prevent="routeScrollTo('#js-services','/')"
            href="javascript:void(0)"
          >About Us</a>
        </li>
        <li>
          <a
            @click.prevent="routeScrollTo('#js-spaces','/')"
            href="javascript:void(0)"
          >Storage Services</a>
        </li>
        <li>
          <a
            @click.prevent="routeScrollTo('#js-footer','/')"
            href="javascript:void(0)"
          >Contact Us</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

export default {
  data() {
    return {
      scrollDirection: "up",
      scrollPosition: window.pageYOffset
    };
  },
  mounted() {
    this.headerToggleInit();
    this.scrollEvent();
  },
  methods: {
    headerToggleInit() {
      this.headerMenu = document.querySelectorAll(".js-header__menu");
      this.navMenu = document.querySelector(".c-menu");
      this.menuMask = document.getElementById("js-menu__mask");
      this.menuClose = document.getElementById("js-menu__close");
      this.navArchor = document
        .querySelector(".o-menu__main")
        .querySelectorAll("a");
      if ((this.headerMenu, this.navMenu, this.menuMask, this.menuClose)) {
        this.eventInit();
      }
    },
    eventInit() {
      this.headerMenu.forEach((el, key) => {
        el.addEventListener("click", this.show.bind(this));
      });
      this.menuClose.addEventListener("click", this.hide.bind(this));
      this.menuMask.addEventListener("click", this.hide.bind(this));
      this.navArchor.forEach((el, index) => {
        el.addEventListener("click", this.hide.bind(this));
      });
    },
    show() {
      this.navMenu.classList.add("c-menu--show");
      this.fadeIn(this.menuMask);
    },
    hide() {
      this.navMenu.classList.remove("c-menu--show");
      this.fadeOut(this.menuMask);
    },
    // fade out
    fadeOut(el) {
      el.style.opacity = 1;

      (function fade() {
        if ((el.style.opacity -= 0.1) < 0) {
          el.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
    },
    // fade in
    fadeIn(el, display) {
      el.style.opacity = 0;
      el.style.display = display || "block";
      (function fade() {
        var val = parseFloat(el.style.opacity);
        if (!((val += 0.1) > 1)) {
          el.style.opacity = val;
          requestAnimationFrame(fade);
        }
      })();
    },
    //Scroll Event
    scrollEvent() {
      window.addEventListener("scroll", this.setScrollDirection.bind(this));
    },
    //Scroll direction
    setScrollDirection() {
      let scroll = window.pageYOffset;
      if (scroll > this.scrollPosition && scroll > 80) {
        this.scrollDirection = "down";
      } else {
        this.scrollDirection = "up";
      }
      this.scrollPosition = scroll;
    },
    routeScrollTo(scrollTo, routeTo) {
      let currentPath = this.$router.history.current.path;
      if (routeTo == currentPath) {
        VueScrollTo.scrollTo(scrollTo);
      } else {
        this.$router.push(routeTo);
        setTimeout(() => {
          VueScrollTo.scrollTo(scrollTo);
        }, 800);
      }
    }
  }
};
</script>

<style>
</style>