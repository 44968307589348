/** add class "numberOnly" inside the input */
export default class NumberOnly {
	constructor() {
		this.numberInput = document.querySelectorAll('.numberOnly');
    }

    init() {
        this.numberOnly();
    }
    
    numberOnly() {

		this.numberInput.forEach((el, index) => {
			el.addEventListener('keypress',(e)=>{
				const isNumber = /^[0-9]$/i.test(e.key);
				return !isNumber ? e.preventDefault() : false;
 			});			
		});

    }
    
}