<template>
  <div class="c-slickhome">
    <slick id="js-slickMain" ref="slickMain" :options="settingsMain" class="c-slickhome__main">
      <div class="o-slick__wrap" v-for="data in galleries" :key="data.id">
        <img :src="`${data.image}`" alt />
      </div>
    </slick>

    <slick id="js-slickThumb" ref="slickThumb" :options="settingsThumb" class="c-slickhome__thumb">
      <div class="o-slick__wrap" v-for="data in galleries" :key="data.id">
        <div class="o-slick__thumbWrap">
          <div
            class="o-slick__thumb"
            :style="{ 'background-image': 'url(' + data.image + ')' }"
          ></div>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from "vue-slick";
export default {
  props: ["galleries"],
  components: {
    Slick
  },
  data() {
    return {
      settingsMain: {
        asNavFor: "#js-slickThumb",
        focusOnSelect: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: false
      },
      settingsThumb: {
        asNavFor: "#js-slickMain ",
        slidesToShow: 8,
        slidesToScroll: 1,
        focusOnSelect: true,
        infinite: true,
        adaptiveHeight: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 6
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 7
            }
          }
        ]
      }
    };
  },
  beforeUpdate() {
    if (this.$refs.slickMain) {
      this.$refs.slickMain.destroy();
    }
    if (this.$refs.slickThumb) {
      this.$refs.slickThumb.destroy();
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.slickMain) {
        this.$refs.slickMain.create(this.settingsMain);
      }
      if (this.$refs.slickThumb) {
        this.$refs.slickThumb.create(this.settingsThumb);
      }
    });
  }
};
</script>

<style>
</style>