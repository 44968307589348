<template>
    <div>
        <div class="c-popupMask js-popupClose"></div>
        <div class="c-popupContainer">
            <div class="c-popupClose js-popupClose">Close</div>
            <div class="c-popupWrap">
                <div class="c-popup c-popupBook" id="js-book__popup">
                    <div class="o-popup__book">
                        <p>
                            We have received your booking request.
                        </p>
                        <p>
                            Our staff will be contacting you within 24 hours to process your booking.
                        </p>
                        <p>
                            Thank you!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Popup from './plugins/PopupPlugin';
export default {
    mounted() {
        this.popupInit()
    },
    methods: {
        popupInit() {
            const popup = new Popup();
            popup.init();
        }
    }
}
</script>

<style>

</style>