<template>
  <footer>
    <div id="js-footer" class="c-footer">
      <router-link to="/" exact class="o-footer__logoLink"></router-link>
      <div class="o-footer__menuWrap">
        <router-link to="/" exact class="js-scroll o-footer__logo">
          <img src="/images/logo.jpg" alt />
        </router-link>
        <ul class="o-footer__menu">
          <li>
            <router-link to="/" exact>Home</router-link>
          </li>
          <li>
            <a
              @click.prevent="routeScrollTo('#js-services','/')"
              href="javascript:void(0)"
            >About Us</a>
          </li>
          <li>
            <a
              @click.prevent="routeScrollTo('#js-spaces','/')"
              href="javascript:void(0)"
            >Storage Services</a>
          </li>
          <li>
            <router-link to="/policy">Privacy Policy</router-link>
          </li>
        </ul>
      </div>
      <div class="o-footer__divider"></div>
      <p>© 2019 EC Storage - All rights Reserved</p>
      <div class="o-footer__socialMediaWrap">
        <ul class="o-footer__socialMedia">
          <li>
            <a
              class="o-footer__icon o-footer__icon--facebook"
              href="https://www.facebook.com/ecstorageph/"
              target="_blank"
            ></a>
          </li>
          <li>
            <a
              class="o-footer__icon o-footer__icon--instagram"
              href="https://www.instagram.com/ecstorageph/?hl=en"
              target="_blank"
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

export default {
  methods: {
    routeScrollTo(scrollTo, routeTo) {
      let currentPath = this.$router.history.current.path;
      if (routeTo == currentPath) {
        VueScrollTo.scrollTo(scrollTo);
      } else {
        this.$router.push(routeTo);
        setTimeout(() => {
          VueScrollTo.scrollTo(scrollTo);
        }, 800);
      }
    }
  }
};
</script>

<style>
</style>