<template>
  <div class="c-banner">
    <img class="c-banner--desktop" :src="`${imageUrl}`" alt />
    <img class="c-banner--mobile" :src="`${imageMobileUrl}`" alt />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: null,
      imageMobileUrl: null
    };
  },
  created() {
    this.setBannerImageUrl();
    this.setBannerImageMobileUrl();
  },
  mounted() {},
  updated() {},
  watch: {
    imageUrl() {}
  },
  methods: {
    setBannerImageUrl() {
      axios
        .get("/api/banner")
        .then(response => {
          this.imageUrl = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    setBannerImageMobileUrl() {
      axios
        .get("/api/mobile-banner")
        .then(response => {
          this.imageMobileUrl = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style>
</style>