//Popup
/**
 * <div data-popup="custom" className="js-popupOpen">Open</div>
 * <div className="js-popupClose">Close</div>
 */
// npm install perfect-scrollbar

import PerfectScrollbar from '../../../../../node_modules/perfect-scrollbar';
export default class PopupPlugin {

	constructor() {
		// variable
		this.popupMask = document.querySelector('.c-popupMask');
		this.popupContainer = document.querySelector('.c-popupContainer');
		this.popupClose = document.querySelectorAll('.js-popupClose');
		this.popupOpen = document.querySelectorAll(".js-popupOpen");
		this.popupWrap = document.querySelector('.c-popupWrap');
		this.popup = document.querySelectorAll('.c-popup');
	}

	init() {
		this.eventInit();
	}

	// fade out
	fadeOut(el) {
		el.style.opacity = 1;

		(function fade() {
			if ((el.style.opacity -= .1) < 0) {
				el.style.display = "none";
			} else {
				requestAnimationFrame(fade);
			}
		})();
	}

	// fade in
	fadeIn(el, display) {
		el.style.opacity = 0;
		el.style.display = display || "block";
		(function fade() {
			var val = parseFloat(el.style.opacity);
			if (!((val += .1) > 1)) {
				el.style.opacity = val;
				requestAnimationFrame(fade);
			}
		})();
	}

	checkPopupHeight() {
		let windowHeight = window.outerHeight;
		let popContainerHeight = this.popupWrap.offsetHeight;
		if (popContainerHeight > windowHeight) {
			this.popupContainer.style.height = '100%';
			const ps = new PerfectScrollbar(this.popupContainer);
		} else {
			this.popupContainer.style.height = 'auto';
		}
	}

	show() {
		let id = this.currentElement.getAttribute('data-popup');
		let content = document.getElementById(id);
		this.component.fadeIn(this.component.popupMask);
		this.component.fadeIn(this.component.popupContainer);
		this.component.fadeIn(content);
		this.component.checkPopupHeight();
	}

	hide() {
		this.fadeOut(this.popupMask);
		this.fadeOut(this.popupContainer);
		this.popup.forEach((el, index, arr) => {
			this.fadeOut(el);
		});
	}

	eventInit() {
		this.popupOpen.forEach((el, index, arr) => {
			el.addEventListener('click', this.show.bind({
				currentElement: el,
				component: this
			}));
		});

		this.popupClose.forEach((el, index, arr) => {
			el.addEventListener('click', this.hide.bind(this));
		});
	}

	/**CALL POPUP */	
	call(contentId) {
		let content = document.getElementById(contentId);
		this.fadeIn(this.popupMask);
		this.fadeIn(this.popupContainer);
		this.fadeIn(content);
		this.checkPopupHeight();
	}
	close() {
		this.fadeOut(this.popupMask);
		this.fadeOut(this.popupContainer);
	}
}

/**
 * SAMPLE
 * <a data-id="1" data-popup="custom" href="javascript:void(0)" class="o-button js-popupOpen">More</a>
 * or manually call
 * 	const popup = new PopupPlugin();
	popup.init();
    popup.call('popdev-target');
 */
