<template>
    <div>
        <div class="c-popupMask js-popupClose"></div>
        <div class="c-popupContainer">
            <div class="c-popupClose js-popupClose">Close</div>
            <div class="c-popupWrap">
                <div class="c-popup c-popupContent" id="popdev-target">
                    <h1>popdev-target</h1>
                </div>
                <div class="c-popup c-popupSpace" id="popup-space">
                    <div class="c-spacesSliderDesktop" >
                        <template v-for="data in popupStorages" >
                            <SlickHome v-if="data.id == $store.state.sliderSelect" :galleries="data.galleries" :key="data.id"/>                            
                        </template>
                    </div>
                </div>
                <div class="c-popup c-popupCustomType" id="customtype">
                    <div class="o-popup__customType">
                        <h3>
                            For custom inquiries, please call us with the following contact numbers:
                        </h3>
                        <ul>
                            <li>
                                <a href="tel:+639989826908">0998 982 6908</a>
                            </li>
                            <li>
                                <a href="tel:+639059402911">0905 940 2911</a>
                            </li>
                            <li>
                                <a href="tel:+6325315165">+632 531 5165</a>
                            </li>
                            <li>
                                <a href="tel:+632 370 4288">+632 370 4288</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SlickHome from './SlickHome'
export default {
    components: {
        SlickHome
    },
    props: ['popupStorages']
}
</script>

<style>

</style>