// valid format
// http://biostall.com/javascript-new-date-returning-nan-in-ie-or-invalid-date-in-safari/
export default class MonthYear {

	constructor() {

	}

	init(calendar) {
		this.dateNow = new Date();
		// console.log('dateNow :'+this.dateNow);

		this.monthNow = this.dateNow.getMonth() + 1;
		// console.log('monthNow :'+this.monthNow);

		this.yearNow = this.dateNow.getFullYear();
		// console.log('yearNow :'+this.yearNow);

		this.dateCurrent = new Date(`${this.monthNow}/01/${this.yearNow}`); //use for comparison
		// console.log('dateCurrent :'+this.dateCurrent);

		calendar.element.querySelector('input').setAttribute('readonly', 'readonly');

		calendar.element.appendChild(document.createElement('div')).classList.add('o-monthyear__calendar');

		calendar.element.querySelector('.js-monthyear__input').appendChild(document.createElement('div')).classList.add('js-monthyear__btn--open'); //add btn-open
		calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').classList.add('o-monthyear__btn--open');

		calendar.element.querySelector('.js-monthyear__input').appendChild(document.createElement('div')).classList.add('js-monthyear__btn--close'); //add btn-close
		calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').classList.add('o-monthyear__btn--close');

		calendar.element.querySelector('.js-monthyear__input').appendChild(document.createElement('div')).classList.add('o-monthyear__error');//append with class


		if (calendar.option == null) {
			this.createCalendar(calendar.element, this.monthNow, this.yearNow, calendar.option, calendar.reference);

			calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').addEventListener('click', () => { //open calendar
				calendar.element.querySelector('.o-monthyear__calendar').style.display = 'block';
				calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').style.display = 'none';
				calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').style.display = 'block';
				calendar.reference.querySelector('.o-monthyear__calendar').style.display = 'none';
				calendar.reference.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').style.display = 'none';
				calendar.reference.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').style.display = 'block';
			});

			calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').addEventListener('click', () => { //close calendar
				calendar.element.querySelector('.o-monthyear__calendar').style.display = 'none';
				calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').style.display = 'block';
				calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').style.display = 'none';
			});
		}

		else if (calendar.option == 'to') {
			calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').addEventListener('click', () => {
				let dateFrom = calendar.reference.querySelector('input');
				if (dateFrom.value != '') {

					// let monthFrom = parseInt(dateFrom.getAttribute('data-month'));
					// let yearFrom = parseInt(dateFrom.getAttribute('data-year'));

					/**custom for ecstorage */
					let dateFromValue = dateFrom.value;
					let dateFromValueSplit = dateFromValue.split(" ");
					let customMonth = dateFromValueSplit[0];
					let customYear = dateFromValueSplit[1];
					let newDate = new Date(`${customYear}-${customMonth}`);
					let monthNumber = newDate.getMonth() + 1;
					let monthFrom = parseInt(monthNumber);
					let yearFrom = parseInt(customYear);

					this.createCalendar(calendar.element, monthFrom, yearFrom, calendar.option, calendar.reference);
					calendar.element.querySelector('.o-monthyear__calendar').style.display = 'block';
					calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').style.display = 'none';
					calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').style.display = 'block';
					calendar.reference.querySelector('.o-monthyear__calendar').style.display = 'none';
					calendar.reference.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').style.display = 'none';
					calendar.reference.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').style.display = 'block';
				} else {
					dateFrom.focus();
					calendar.element.querySelector('.o-monthyear__error').innerHTML = 'Set start date first';//set error
				}
			});

			calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').addEventListener('click', () => {
				calendar.element.querySelector('.o-monthyear__calendar').style.display = 'none';
				calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').style.display = 'block';
				calendar.element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').style.display = 'none';
			});;

		}

		// document.addEventListener('click', (event) => {
		//   let isClickInside = calendar.element.contains(event.target);
		//   if (!isClickInside) {
		//      calendar.element.querySelector('.o-monthyear__calendar').style.display = 'none';

		//      calendar.element.querySelector('.monthyear__error') ? calendar.element.querySelector('.monthyear__error').innerHTML = '' : ''; //clear error

		//   }
		// });

	}

	eventInit(element, month, year, option, reference) {
		const monthyear__prev = element.querySelector('#js-monthyear__prev');
		const monthyear__next = element.querySelector('#js-monthyear__next');

		const yearFrom = year;
		const yearTo = year + 2;

		if (year === yearFrom) {
			monthyear__prev.style.display = 'none';
		}

		monthyear__prev.addEventListener('click', () => {
			if (option == 'to') {
				if (year > yearFrom && year <= yearTo) {
					year--;
					this.changeCalendar(element, month, year, option, reference);
					monthyear__next.style.display = '';
				}
				if (year == yearFrom) {
					monthyear__prev.style.display = 'none';
				}
			} else {
				if (year > yearFrom) {
					year--;
					this.changeCalendar(element, month, year, option, reference);
				}
				if (year == yearFrom) {
					monthyear__prev.style.display = 'none';
				}
			}

		});

		monthyear__next.addEventListener('click', () => {
			if (option == 'to') {
				if (year >= yearFrom && year < yearTo) {
					year++;
					this.changeCalendar(element, month, year, option, reference);
					monthyear__prev.style.display = '';
				}
				if (year == yearTo) {
					monthyear__next.style.display = 'none';
				}
			} else {
				year++;
				this.changeCalendar(element, month, year, option, reference);
				monthyear__prev.style.display = '';
			}

		});
	}

	createCalendar(element, month, year, option, reference) {
		const createCalendar = element.querySelector('.o-monthyear__calendar');
		const years = this.createYearData(year);
		const months = this.createMonthData(element, month, year, option, reference);
		createCalendar.innerHTML = years + `<div class="o-monthyear__months">${months}</div>`;
		this.eventInit(element, month, year, option, reference);
		this.selectMonth(element, month, year, option, reference);
	}

	changeCalendar(element, month, year, option, reference) {
		const changeMonths = element.querySelector('.o-monthyear__months');
		const changeYear = element.querySelector('.o-monthyear__year');
		changeYear.innerHTML = year;
		changeMonths.innerHTML = this.createMonthData(element, month, year, option, reference);
		this.selectMonth(element, month, year, option, reference);
	}

	createMonthData(element, month, year, option, reference) {

		let monthData = '';

		const months = [
			{ name: 'Jan', fullmonth: 'January', month: 1, year: year },
			{ name: 'Feb', fullmonth: 'February', month: 2, year: year },
			{ name: 'Mar', fullmonth: 'March', month: 3, year: year },
			{ name: 'Apr', fullmonth: 'April', month: 4, year: year },
			{ name: 'May', fullmonth: 'May', month: 5, year: year },
			{ name: 'Jun', fullmonth: 'June', month: 6, year: year },
			{ name: 'Jul', fullmonth: 'July', month: 7, year: year },
			{ name: 'Aug', fullmonth: 'August', month: 8, year: year },
			{ name: 'Sep', fullmonth: 'September', month: 9, year: year },
			{ name: 'Oct', fullmonth: 'October', month: 10, year: year },
			{ name: 'Nov', fullmonth: 'November', month: 11, year: year },
			{ name: 'Dec', fullmonth: 'December', month: 12, year: year },
		];

		if (option == 'to') {

			const dateReference = reference.querySelector('input');

			/**custom */
			let dateFromValue = dateReference.value;
			// console.log('dateFromValue :' +dateFromValue);

			let dateFromValueSplit = dateFromValue.split(" ");
			// console.log('dateFromValueSplit :' +dateFromValueSplit);
			let customMonth = dateFromValueSplit[0];

			let valueMonth;

			if (customMonth == 'January' || customMonth == 'january') {
				valueMonth = 1;
			} 
			else if (customMonth == 'February' || customMonth == 'february') {
				valueMonth = 2;
			}
			else if (customMonth == 'March' || customMonth == 'march') {
				valueMonth = 3;
			}
			else if (customMonth == 'April' || customMonth == 'april') {
				valueMonth = 4;
			}
			else if (customMonth == 'May' || customMonth == 'may') {
				valueMonth = 5;
			}
			else if (customMonth == 'June' || customMonth == 'june') {
				valueMonth = 6;
			}
			else if (customMonth == 'July' || customMonth == 'july') {
				valueMonth = 7;
			}
			else if (customMonth == 'August' || customMonth == 'august') {
				valueMonth = 8;
			}
			else if (customMonth == 'September' || customMonth == 'september') {
				valueMonth = 9;
			}
			else if (customMonth == 'October' || customMonth == 'october') {
				valueMonth = 10;
			}
			else if (customMonth == 'November' || customMonth == 'november') {
				valueMonth = 11;
			}
			else if (customMonth == 'December' || customMonth == 'december') {
				valueMonth = 12;
			}
			// console.log('valueMonth :' +valueMonth);

			let valueYear = dateFromValueSplit[1];
			// console.log('valueYear :' +valueYear);

			let monthFrom = parseInt(valueMonth);
			let yearFrom = parseInt(valueYear);

			// console.log('monthFrom :' +monthFrom);
			// console.log('yearFrom :' +yearFrom);

			let dateReferenceFromYear = yearFrom;
			let dateReferenceFromMonth = monthFrom;
			let dateReferenceToYear = parseInt(dateReferenceFromYear) + 2;
			/**custom */


			// const dateReferenceFromYear = dateReference.getAttribute('data-year');
			// const dateReferenceFromMonth = dateReference.getAttribute('data-month');
			// const dateReferenceToYear = parseInt(dateReference.getAttribute('data-year')) + 2;

			let dateReferenceFrom = new Date(`${dateReferenceFromMonth}/01/${dateReferenceFromYear}`);
			let dateReferenceTo = new Date(`${dateReferenceFromMonth}/01/${dateReferenceToYear}`);
			// console.log('dateReferenceFrom: '+dateReferenceFrom);
			// console.log('dateReferenceTo :'+dateReferenceTo);
			

			months.forEach((data, index) => {
				let dateCompare = new Date(`${data.month}/01/${data.year}`);
				if (dateCompare >= dateReferenceFrom && dateCompare <= dateReferenceTo) {
					monthData += `<div class="o-monthyear__month js-monthyear__month--active" name="month" data-month="${data.month}" data-fullmonth="${data.fullmonth}" data-year="${data.year}">${data.name}</div>`;
				}
				else {
					monthData += `<div class="o-monthyear__month o-monthyear__month--inactive" data-month="${data.month}" data-fullmonth="${data.fullmonth}" data-year="${data.year}">${data.name}</div>`;
				}
			});
		} else { //default 1
			months.forEach((data, index) => {
				let dateCompare = new Date(`${data.month}/01/${data.year}`);
				if (dateCompare == this.dateCurrent) {
					monthData += `<div class="o-monthyear__month o-monthyear__month--now js-monthyear__month--active" data-month="${data.month}" data-fullmonth="${data.fullmonth}" data-year="${data.year}">${data.name}</div>`;
				}
				else if (dateCompare < this.dateCurrent) {
					monthData += `<div class="o-monthyear__month o-monthyear__month--inactive" data-month="${data.month}" data-fullmonth="${data.fullmonth}" data-year="${data.year}">${data.name}</div>`;
				}
				else {
					monthData += `<div class="o-monthyear__month js-monthyear__month--active" name="month" data-month="${data.month}" data-fullmonth="${data.fullmonth}" data-year="${data.year}">${data.name}</div>`;
				}
			});
		}

		return monthData;

	}

	createYearData(year) {
		const controls = `<div id="js-monthyear__prev" class="o-monthyear__prev">Prev</div>
	  					<div id="js-monthyear__next" class="o-monthyear__next">Next</div>`;

		let yearData = `<div class="o-monthyear__year">${year}</div>`;

		return `<div class="o-monthyear__years">${controls} ${yearData}</div>`;
	}

	selectMonth(element, month, year, option, reference) {
		const monthYearInput = element.querySelector('input');
		const selectMonth = element.querySelectorAll('.js-monthyear__month--active');
		selectMonth.forEach((el, index) => {
			el.addEventListener('click', () => {
				let monthAttr = el.getAttribute('data-month');
				let monthFullAttr = el.getAttribute('data-fullmonth');
				let yearAttr = el.getAttribute('data-year');
				monthYearInput.setAttribute('data-month', monthAttr);
				monthYearInput.setAttribute('data-fullmonth', monthFullAttr);
				monthYearInput.setAttribute('data-year', yearAttr);
				monthYearInput.value = monthFullAttr + ' ' + yearAttr;
				element.querySelector('.o-monthyear__calendar').style.display = 'none';
				element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--open').style.display = 'block';
				element.querySelector('.js-monthyear__input').querySelector('.js-monthyear__btn--close').style.display = 'none';
				reference.querySelector('.o-monthyear__error').innerHTML = ''; //clear error
				if (option != 'to') {
					if (reference.querySelector('input').value != '') {
						reference.querySelector('input').value = '';
					}
				}
			});
		});
	}


}
