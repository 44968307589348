<template>
    <div id="js-policy" class="c-policy">

        <div class="o-policy__content">
            <h1>Privacy Policy</h1>
            <div class="o-policy__info">
                <p>
                    Keep All Storage Inc., operator of EC Storage, complies with the Data Privacy Act of 2012 (RA 10173) and its implementing rules and regulations, and is committed in safeguarding your privacy and personal information. This Privacy Policy, hereinafter referred to as “Policy”, explains how we collect, protect, use or share information when you access our website and/or apply for and avail of our products and services.
                </p>
                <p>
                    The Policy describes Keep All Storage Inc.’s general privacy practices that apply to personal information we collect, use and share about consumers and other individuals with our clients, business partners, supplier and other organizations with which Keep All Storage, Inc. has or contemplates a business relationship.
                </p>                        
            </div>

            <div class="o-policy__info">
                <h2>SCOPE AND PURPOSE OF DATA COLLECTION</h2>
                <p>
                    The Policy applies to all personal information collected with your consent for the purposes of providing
                    you with our products and services. By using our website or by giving us your personal information, you
                    are enabling us to give you access to more information about our products and services and allow the
                    facilitation of communication, business relations management, as well as information on the
                    Corporation’s latest products and services. Likewise, personal information collected shall be subject to
                    further processing for the sole purpose of conducting market research and development of our products
                    and services.
                </p>
                <p>
                    The information may include, among others:
                </p>
                <p>
                    Personal contact information including your name, address, e-mail, and phone number,
                    Demographic information such as date of birth, age, gender, and employment details;
                    Transactional information such as purchasing authority, product usage, service preferences, and payment details;
                </p>
                <p>
                    Images via CCTV and other similar recording devices and processes which may be observed when visiting our offices;
                </p>
                <p>
                    In the course of availing our products and services, we also collect information about your transactions and dealings which include your account activities, movements and interactions with third parties such as merchants and other suppliers.
                </p>
            </div>

            <div class="o-policy__info">
                <h2>
                    HOW YOUR PERSONAL INFORMATION IS TAKEN
                </h2>
                <p>
                    We use cookies and other tracking technology which collect certain kinds of information when you interact with our website. Likewise, any information voluntary provided under the Booking page and/or Contact us page are being stored.
                </p>
                <p>
                    We may collect information about you from other legitimate sources for the purpose of providing you with our products, services, and other information relating to the company. Such sources include third party social networking sites with whom you have given consent for the disclosure of information relating to you and were otherwise lawfully permitted.
                </p>
            </div>

            <div class="o-policy__info">
                <h2>
                    SHARING OF PERSONAL INFORMATION
                </h2>
                <p>
                    Keep All Storage Inc. does not share your personal information to any person or third party without your express consent. By voluntarily providing your personal information, however, you are deemed to have authorized us to share your personal information with our suppliers, agents, contractors, collectors, service providers, our affiliates, financial and legal advisers, such as but not limited to auditors, consultants, accountants and lawyers. When we do so, rest assured that your personal information will be used in a manner consistent with the purposes for which it was originally collected or for any other purposes for which you subsequently consented to.
                </p>
            </div>

            <div class="o-policy__info">
                <h2>
                    SECURITY OF PERSONAL INFORMATION
                </h2>
                <p>
                    As the Personal Information Collector, we will take all measures necessary to prevent any breach and disclosure of your personal information.
                </p>
            </div>

            <div class="o-policy__info">
                <h2>
                    RETENTION OF PERSONAL INFORMATION
                </h2>
                <p>
                    Keep All Storage Inc. will only retain the personal information collected on our website for as long as necessary to provide the services, products and information you request or as permitted by applicable law.
                </p>
                <p>
                    This means that we may retain your personal information only within a reasonable timeframe after your last interaction or transaction with us. When the personal information that we collect is no longer required, we undertake to destroy or delete it in a secure manner.
                </p>
            </div>

            <div class="o-policy__info">
                <h2>
                    YOUR RIGHTS
                </h2>
                <p>
                    As we respect your rights to privacy, you have the option to advise us:
                </p>
                <p>
                    Not to send you marketing materials via email;
                </p>
                <p>
                    Not to share your information with other companies that we have business with provided that such information is not critical nor required by applicable laws and regulations in maintaining the services that you have availed with us;
                </p>
                <p>
                    To provide you with information that we currently have about you subject to restrictions applied to us as a company operating in the Philippines by certain laws and regulations;
                </p>
                <p>
                    To update your information;
                </p>
                <p>
                    Anything about your other concerns relating to how we collect, use, share, protect or dispose your information.
                </p>
            </div>

            <div class="o-policy__info">
                <h2>
                    CONTACT US
                </h2>
                <p>
                    For any queries, clarifications or requests on any aspect of this Policy, the exercise of your rights pertaining to your personal information or to provide any feedback that you may have about our processing of personal information, please visit our office or get in touch with us via 0917 851 7673. You may also email us at ecstorageph@gmail.com .
                </p>
                <p>
                    You may also write our Data Privacy Officer at: 515 Katarungan St., Brgy. Plainview Mandaluyong, 1550 Philippines
                </p>
            </div>

            <div class="o-policy__info">
                <h2>
                    POLICY CHANGES
                </h2>
                <p>
                    We reserve the right to make changes to this Privacy at any time. If we make any substantial changes to this privacy policy and the way in which we use your personal data, we will post these changes on this website.
                </p>
            </div>


        </div>
    </div> 
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style>

</style>