<template>
  <div class="c-booking">
    <form class="o-input__form" action>
      <div class="o-input__wrap">
        <div class="o-input o-input--fixed">
          <div id="js-monthyear__from" class="c-monthyear">
            <div class="o-monthyear__input js-monthyear__input">
              <input ref="startDate" class="o-input__text" type="text" placeholder="Choose a Date" />
            </div>
          </div>
          <span class="o-input__label--float" required>Start</span>
        </div>
      </div>
      <div class="o-input__wrap">
        <div class="o-input o-input--fixed">
          <div id="js-monthyear__to" class="c-monthyear">
            <div class="o-monthyear__input js-monthyear__input" required>
              <input ref="endDate" class="o-input__text" type="text" placeholder="Choose a Date" />
            </div>
          </div>
          <span class="o-input__label--float">End</span>
        </div>
      </div>
      <div class="o-input__wrap">
        <div class="o-input o-input--select o-input--fixed">
          <select @change="branchSelect" ref="branch" placeholder="Select a Branch" required>
            <option value selected disabled>--Select Branch--</option>
            <template v-for="data in this.$store.state.branches">
              <option :value="data.id" :key="data.id" v-text="data.branch_name"></option>
            </template>
          </select>
          <span class="o-input__label--float">Branch</span>
        </div>
      </div>
      <div class="o-input__wrap">
        <div class="o-input__tooltipWrap">
          <div class="o-tooltip">
            <div class="o-tooltip__content">
              <ul>
                <li
                  v-for="data in this.$store.state.storages"
                  :key="data.id"
                  v-text="data.unit_type"
                ></li>
              </ul>
            </div>
          </div>
          <div class="o-input o-input--select o-input--fixed">
            <select onChange ref="storageSpace" placeholder="Storage Space" required>
              <option value selected disabled>--Select Storage Space--</option>
              <template v-for="data in this.$store.getters.branchSpacesGet">
                <option :value="data.id" :key="data.id" v-text="data.service.unit_type"></option>
              </template>
            </select>
            <span class="o-input__label--float">Storage Space</span>
          </div>
        </div>
      </div>
      <div class="o-button__wrap">
        <button type="submit" class="o-button" @click="submitForm">Book Now!</button>
      </div>
    </form>
  </div>
</template>

<script>
import MonthYear from "./plugins/MonthYear";
import { mapActions } from "vuex";
export default {
  mounted() {
    this.datePickerInit();
  },
  updated() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapActions(["formChange"]),
    formAction(payload) {
      this.$store.dispatch("formChange", payload);
    },
    datePickerInit() {
      this.dateFrom = document.getElementById("js-monthyear__from");
      this.dateTo = document.getElementById("js-monthyear__to");
      const optionFrom = {
        element: this.dateFrom,
        option: null,
        reference: this.dateTo
      };
      const optionTo = {
        element: this.dateTo,
        option: "to",
        reference: this.dateFrom
      };
      const monthyear = new MonthYear();
      monthyear.init(optionFrom);
      monthyear.init(optionTo);
    },
    submitForm(e) {
      e.preventDefault();

      /**
       * Update Store State
       */
      this.$store.dispatch("startDateSelect", this.$refs.startDate.value);
      this.$store.dispatch("endDateSelect", this.$refs.endDate.value);
      this.$store.dispatch("branchSelect", this.$refs.branch.value);
      this.$store.dispatch("spaceSelect", this.$refs.storageSpace.value);

      this.$router.push("/book");
    },
    branchSelect() {
      const branchSelect = this.$refs.branch.value;
      this.$store.dispatch("branchSelect", branchSelect);
    }
  }
};
</script>

<style>
</style>