<template>
  <div class="c-fservice">
    <div class="c-fservice__wrap">
      <div class="c-fservice__title">
        <h1 class="o-heading__h1">FULL SERVICE STORAGE</h1>
        <p
          class="o-paragraph"
        >On demand storage space straight from your door. We pack, move and store for less than self storage.</p>
      </div>
      <div class="c-fservice__services">
        <div class="c-fservice__service">
          <div class="c-fservice__icon">
            <div class="o-imgWrap">
              <img src="/images/fservice__packaging.png" alt />
            </div>
            <div class="c-fservice__label">Packaging</div>
          </div>
          <div class="c-fservice__desc">
            <p
              class="o-paragraph"
            >An experienced team to help you organize and safely pack your items for your EC journey with us.</p>
          </div>
        </div>
        <div class="c-fservice__service">
          <div class="c-fservice__icon">
            <div class="o-imgWrap">
              <img src="/images/fservice__trucking.png" alt />
            </div>
            <div class="c-fservice__label">Trucking</div>
          </div>
          <div class="c-fservice__desc">
            <p
              class="o-paragraph"
            >Transporting your items has never been this EC with a truck waiting for you and your items to be transferred into their units.</p>
          </div>
        </div>
        <div class="c-fservice__service">
          <div class="c-fservice__icon">
            <div class="o-imgWrap">
              <img src="/images/fservice__storing.png" alt />
            </div>
            <div class="c-fservice__label">Storing</div>
          </div>
          <div class="c-fservice__desc">
            <p
              class="o-paragraph"
            >It’s EC to choose the perfect unit that suits your needs with our customizable unit sizes and flexible payment terms.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>