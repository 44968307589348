<template>
  <div id="js-testimonial" class="c-testimonial">
    <div class="o-heading__h1">Testimonials</div>
    <slick ref="slick" :options="slickOptions" class="c-slider">
      <div class="o-slider__wrap" v-for="data in testimonials" :key="data.id">
        <div class="o-slider__content">
          <div class="o-testimonial__sliderContent">
            <div class="o-imgWrap">
              <img :src="`${data.author_img}`" alt />
            </div>
            <p class="o-paragraph" v-text="data.testimony"></p>
            <div class="o-testimonial__author" v-text="data.author"></div>
          </div>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from "vue-slick";
export default {
  components: {
    Slick
  },
  data() {
    return {
      testimonials: null,
      slickOptions: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 10000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
        ]
      }
    };
  },
  created() {
    this.getTestimonial();
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy();
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions);
      }
    });
  },
  methods: {
    getTestimonial() {
      axios
        .get("api/testimonials")
        .then(response => {
          this.testimonials = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style>
</style>