require('./bootstrap');

try {
  window.$ = window.jQuery = require('jquery');
} catch (e) { }

/**
 * VUE
 */
import VueRouter from 'vue-router'
import App from './App.vue'
import { routes } from './routes'
import { store } from './store/store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#root')