<template>
    <div class="c-home">
        <Popup :popupStorages="this.$store.state.storages"/>
        <Banner/>
        <Booking/>
        <Fservice/>
        <Spaces :spacesStorages="this.$store.state.storages"/>
        <Services/>
        <Testimonial/>
        <Map/>
    </div>
</template>

<script>
import Popup from '../Popup'
import Banner from '../Banner'
import Booking from '../Booking'
import Fservice from '../Fservice'
import Spaces from '../Spaces'
import Services from '../Services'
import Testimonial from '../Testimonial'
import Map from '../Map'

export default {
    components: {
        Popup,
        Banner,
        Booking,
        Fservice,
        Spaces,
        Services,
        Testimonial,
        Map
    },
    data() {
        return {
            
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>

<style>

</style>