<template>
    <div class="c-map">
        <div id="js-map" class="c-map__wrap">
            <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1h8mYrnyVOZo_VVHY4iICSR4iFje8WN0e" width="640" height="480"></iframe>
        </div>
    </div> 
</template>

<script>
export default {

}
</script>

<style>

</style>