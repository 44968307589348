<template>
  <div class="c-book">
    <PopupBook />
    <div class="c-steps">
      <div class="c-rental">
        <h1 class="o-heading__h1">Booking</h1>
        <div class="o-rental__mainWrap">
          <div class="o-rental__wrap">
            <img src="/images/rental__icon--inquire.png" alt />
            <span>1. Inquire</span>
          </div>
          <div class="o-rental__wrap">
            <img src="/images/rental__icon--pay.png" alt />
            <span>2. Pay</span>
          </div>
          <div class="o-rental__wrap">
            <img src="/images/rental__icon--store.png" alt />
            <span>3. Store</span>
          </div>
        </div>
      </div>
    </div>

    <!-- SLIDER -->
    <div class="o-sectionWrap">
      <div id="js-bookStorage" class="c-storage">
        <div class="o-heading__h1">Storage Services</div>
        <div class="c-slider--book">
          <slick
            id="js-slider__book"
            ref="slick"
            :options="slickOptions"
            class="o-slider__container"
          >
            <div class="o-slider__wrap" v-for="data in this.$store.state.storages" :key="data.id">
              <div class="o-slider__content">
                <div class="o-imgWrap">
                  <img :src="`${data.unit_image}`" alt />
                </div>
                <div class="o-slider__contentMain">
                  <div class="o-heading__h3" v-text="data.unit_type"></div>
                  <div v-html="data.details"></div>
                </div>
              </div>
            </div>
          </slick>
        </div>
      </div>

      <div id="js-bookForm" class="o-formWrap">
        <form class="o-input__form" action @submit.prevent="bookSubmit">
          <div class="o-heading__h3">Please complete the following:</div>
          <div class="o-input__groupWrap">
            <div class="o-input__wrap">
              <div class="o-input o-input--select o-input--fixed">
                <select
                  @change="branchSelect"
                  ref="branch"
                  :value="[this.$store.state.branchSelect !=0 ? this.$store.state.branchSelect : '' ]"
                  placeholder="Select a Branch"
                >
                  <option value selected disabled>--Select Branch--</option>
                  <template v-for="data in this.$store.state.branches">
                    <option :value="data.id" :key="data.id" v-text="data.branch_name"></option>
                  </template>
                </select>
                <span class="o-input__label--float">Branch</span>
              </div>
            </div>
            <div class="o-input__wrap">
              <div class="o-input__tooltipWrap">
                <div class="o-tooltip">
                  <div class="o-tooltip__content">
                    <ul>
                      <li
                        v-for="data in this.$store.state.storages"
                        :key="data.id"
                        v-text="data.unit_type"
                      ></li>
                    </ul>
                  </div>
                </div>
                <div class="o-input o-input--select o-input--fixed">
                  <select
                    ref="storageSpace"
                    :value="this.$store.state.spaceSelect"
                    placeholder="Storage Space"
                  >
                    <option value selected disabled>--Select Storage Space--</option>
                    <template v-for="data in this.$store.getters.branchSpacesGet">
                      <option :value="data.id" :key="data.id" v-text="data.service.unit_type"></option>
                    </template>
                  </select>
                  <span class="o-input__label--float">Storage Space</span>
                </div>
              </div>
            </div>
          </div>
          <div class="o-input__groupWrap">
            <div class="o-input__wrap">
              <div class="o-input o-input--fixed">
                <div id="js-monthyear__from" class="c-monthyear">
                  <div class="o-monthyear__input js-monthyear__input">
                    <input
                      ref="startDate"
                      :value="this.$store.state.startDate"
                      class="o-input__text"
                      type="text"
                      placeholder="Choose a Date"
                    />
                  </div>
                </div>
                <span class="o-input__label--float">Start</span>
              </div>
            </div>
            <div class="o-input__wrap">
              <div class="o-input o-input--fixed">
                <div id="js-monthyear__to" class="c-monthyear">
                  <div class="o-monthyear__input js-monthyear__input">
                    <input
                      ref="endDate"
                      :value="this.$store.state.endDate"
                      class="o-input__text"
                      type="text"
                      placeholder="Choose a Date"
                    />
                  </div>
                </div>
                <span class="o-input__label--float">End</span>
              </div>
            </div>
          </div>
          <div class="o-input__wrap">
            <div class="o-input">
              <input ref="fullName" class="o-input__text" type="text" placeholder maxlength="25" />
              <span class="o-input__label--float">Full Name</span>
            </div>
          </div>
          <div class="o-input__groupWrap">
            <div class="o-input__wrap">
              <div class="o-input">
                <input ref="email" class="o-input__text" type="text" placeholder />
                <span class="o-input__label--float">Email</span>
              </div>
            </div>
            <div class="o-input__wrap">
              <div class="o-input">
                <input ref="contact" class="o-input__text numberOnly" type="text" placeholder />
                <span class="o-input__label--float">Phone Number</span>
              </div>
            </div>
          </div>
          <div class="o-book__checkNote">Other Services :</div>
          <div class="o-input__groupCheckWrap">
            <div class="o-input__groupCheck">
              <div class="o-input__checkWrap">
                <input
                  ref="trucking"
                  @change="onCheckTrucking"
                  class="o-input__check"
                  type="checkbox"
                  placeholder
                />
                <p>Trucking</p>
              </div>
              <div ref="truckingInputWrap" class="o-input__wrap">
                <div class="o-input">
                  <input ref="truckingAddress" class="o-input__text" type="text" placeholder />
                  <span class="o-input__label--float">Address</span>
                </div>
              </div>
            </div>
            <div class="o-input__groupCheck">
              <div class="o-input__checkWrap">
                <input
                  ref="packing"
                  @change="onCheckPacking"
                  class="o-input__check"
                  type="checkbox"
                  placeholder
                />
                <p>Packing</p>
              </div>
              <div ref="packingInputWrap" class="o-input__wrap">
                <div class="o-input">
                  <input ref="packingAddress" class="o-input__text" type="text" placeholder />
                  <span class="o-input__label--float">Address</span>
                </div>
              </div>
            </div>
          </div>

          <div class="o-book__note">
            <p>To our valued clients, we assure you that the information you provided with us will be used only for our records and will not be disclosed to public.</p>
          </div>
          <div class="o-book__note--google">
            <p>
              This site is protected by reCAPTCHA and the Google
              <a
                href="https://policies.google.com/privacy"
              >Privacy Policy</a> and
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
          </div>

          <div class="c-error" v-if="errors != null">
            <div class="o-error__msg" v-for="data in errors" :key="data.id" v-text="data[0]"></div>
          </div>

          <div class="o-button__wrap">
            <button ref="btnBookSubmit" type="submit" class="o-button">Book Now!</button>
          </div>
          <div
            id="js-book__popupTrigger"
            class="o-popup__hiddenBtn js-popupOpen"
            data-popup="js-book__popup"
          ></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MonthYear from "./../plugins/MonthYear";
import NumberOnly from "./../plugins/NumberOnly";
import PopupPlugin from "./../plugins/PopupPlugin";
import PopupBook from "../PopupBook";
import Slick from "vue-slick";

import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LdkuawUAAAAAI-uX_qYMH2v9xi1AGU3Gfg9IRr9" });

export default {
  components: {
    PopupBook,
    Slick
  },
  data() {
    return {
      errors: null,
      slickOptions: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: true
      }
    };
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy();
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions);
      }
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.datePickerInit();
    this.numberOnlyInit();
    this.recaptcha();
  },
  methods: {
    recaptcha() {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha("homepage").then(token => {
          // console.log(token); // Will print the token
        });
      });
    },
    datePickerInit() {
      this.dateFrom = document.getElementById("js-monthyear__from");
      this.dateTo = document.getElementById("js-monthyear__to");
      const optionFrom = {
        element: this.dateFrom,
        option: null,
        reference: this.dateTo
      };
      const optionTo = {
        element: this.dateTo,
        option: "to",
        reference: this.dateFrom
      };
      const monthyear = new MonthYear();
      monthyear.init(optionFrom);
      monthyear.init(optionTo);
    },
    numberOnlyInit() {
      const numberonly = new NumberOnly();
      numberonly.init();
    },
    branchSelect() {
      const branchSelect = this.$refs.branch.value;
      this.$store.dispatch("branchSelect", branchSelect);
    },
    bookSubmit(e) {
      e.preventDefault();

      /**
       * Update Store State
       */
      this.$store.dispatch("startDateSelect", this.$refs.startDate.value);
      this.$store.dispatch("endDateSelect", this.$refs.endDate.value);
      this.$store.dispatch("branchSelect", this.$refs.branch.value);
      this.$store.dispatch("spaceSelect", this.$refs.storageSpace.value);

      const data = {
        branch_id: this.$refs.branch.value,
        storage_id: this.$refs.storageSpace.value,
        start_date: this.$refs.startDate.value,
        end_date: this.$refs.endDate.value,
        name: this.$refs.fullName.value,
        email: this.$refs.email.value,
        contact: this.$refs.contact.value,
        trucking: this.$refs.trucking.checked,
        packing: this.$refs.packing.checked,
        trucking_address: this.$refs.truckingAddress.value,
        packing_address: this.$refs.packingAddress.value
      };

      this.sendingBook();

      axios
        .post("/api/book", data)
        .then(response => {
          this.clearError();
          this.unsentBook();
          this.showSuccess();
        })
        .catch(error => {
          this.errors = error.response.data.errors;
          this.unsentBook();
        });
    },
    sendingBook() {
      this.$refs.btnBookSubmit.innerHTML = "Sending...";
      this.$refs.btnBookSubmit.classList.add("o-button--sending");
      this.$refs.btnBookSubmit.disabled = true;
    },
    unsentBook() {
      this.$refs.btnBookSubmit.innerHTML = "Book Now!";
      this.$refs.btnBookSubmit.classList.remove("o-button--sending");
      this.$refs.btnBookSubmit.disabled = false;
    },
    showSuccess() {
      this.clearForm();
      this.callPopup();
    },
    clearForm() {
      this.$refs.startDate.value = "";
      this.$refs.endDate.value = "";
      this.$refs.fullName.value = "";
      this.$refs.email.value = "";
      this.$refs.contact.value = "";
      this.$refs.trucking.checked = false;
      this.$refs.packing.checked = false;
      this.$refs.truckingAddress = "";
      this.$refs.packingAddress = "";
      this.$refs.truckingInputWrap.classList.remove("o-input__wrap--show");
      this.$refs.packingInputWrap.classList.remove("o-input__wrap--show");
    },
    clearError() {
      this.errors = null;
    },
    callPopup() {
      const popup = new PopupPlugin();
      popup.call("js-book__popup");
    },
    onCheckTrucking(e) {
      if (e.target.checked) {
        this.$refs.truckingInputWrap.classList.add("o-input__wrap--show");
      } else {
        this.$refs.truckingAddress.value = ""; //remove value when unchecked --kd
        this.$refs.truckingInputWrap.classList.remove("o-input__wrap--show");
      }
    },
    onCheckPacking(e) {
      if (e.target.checked) {
        this.$refs.packingInputWrap.classList.add("o-input__wrap--show");
      } else {
        this.$refs.packingAddress.value = ""; //remove value when unchecked --kd
        this.$refs.packingInputWrap.classList.remove("o-input__wrap--show");
      }
    }
  }
};
</script>

<style>
</style>